import React, { useEffect, useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import axios from 'axios';
import '../style/CreatePlan.css'; // Import CSS file

const CreatePlan = ({ onSave, onClose, isEditing = false, existingPlan = null }) => {
  const [plan, setPlan] = useState({
    academy_name: '',
    sport_name: '',
    max_avail_arenas: 1,
    price_per_arena: '',
    GST: '',
    id: null // Initialize id as null
  });
  const [sportCenters, setSportCenters] = useState([]); // Unique academy names
  const [error, setError] = useState('');
  const [academySportsMap, setAcademySportsMap] = useState({}); // State to hold the academy-sports mapping
  const [selectedAcademy, setSelectedAcademy] = useState("");

  // Populate form fields if editing
  useEffect(() => {
    if (isEditing && existingPlan) {
      console.log('Pre-populating form with existing plan:', existingPlan); // Debugging
      setPlan({
        academy_name: existingPlan.academy_name || '',
        sport_name: existingPlan.sport_name || '',
        max_avail_arenas: existingPlan.max_avail_arenas || 1,
        price_per_arena: existingPlan.price_per_arena || '',
        GST: existingPlan.GST || '',
        id: existingPlan.id || ''
      });
      setSelectedAcademy(existingPlan.academy_name || "");
    } else {
      // Reset form when not editing
      setPlan({
        academy_name: '',
        sport_name: '',
        max_avail_arenas: 1,
        price_per_arena: '',
        GST: '',
        id: null
      });
      setSelectedAcademy("");
    }
  }, [isEditing, existingPlan]);

  // Fetch academies data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://admin.machaxi.com/summer-api/get-data', {
          headers: {
            'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
          }
        }); // Use a dedicated endpoint

        // Directly access academies from the response data
        const academies = response.data.academies;

        // Check if academies is defined and is an array
        if (!Array.isArray(academies)) {
          throw new Error('Expected academies to be an array');
        }

        // Create a mapping of academy names to sports
        const academySportsMap = {};
        academies.forEach(item => {
          const { academy_name, sport_name } = item;
          if (!academySportsMap[academy_name]) {
            academySportsMap[academy_name] = [];
          }
          academySportsMap[academy_name].push(sport_name);
        });

        const centers = Object.keys(academySportsMap);
        setSportCenters(centers);
        setAcademySportsMap(academySportsMap);
      } catch (error) {
        console.error('Error fetching academies:', error);
        setError('Failed to fetch academies. Please try again.');
      }
    };

    fetchData();
  }, []);

  // Handle changes in the plan fields
  const handlePlanChange = (field, value) => {
    const updatedPlan = { ...plan, [field]: value };
    // Reset sport_name if academy_name changes
    if (field === 'academy_name') {
      updatedPlan.sport_name = '';
      setSelectedAcademy(value);
    }
    setPlan(updatedPlan);
  };

  // Handle form submission to add or edit plans
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate form fields
    if (!plan.academy_name || !plan.sport_name || !plan.max_avail_arenas || !plan.price_per_arena || !plan.GST) {
      setError('Please fill in all required fields for the plan.');
      return;
    }

    // Prepare formData with appropriate flags
    const formData = {
      id: isEditing ? plan.id : null,
      academy_name: plan.academy_name,
      sport_name: plan.sport_name,
      max_avail_arenas: parseInt(plan.max_avail_arenas, 10),
      price_per_arena: parseFloat(plan.price_per_arena),
      GST: parseFloat(plan.GST),
      isCreatePlan: isEditing ? 0 : 1,
      isCreateAddon: 0,
      isCreateDiscount: 0,
      isEditPlan: isEditing ? 1 : 0,
      isEditAddon: 0,
      isEditDiscount: 0
    };

    try {
      const response = await axios.post('https://admin.machaxi.com/summer-api/admin-post-data', formData, {
        headers: {
          'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
        }
      });
      console.log('Response:', response.data);
      setError(''); // Clear any existing errors
      onSave(response.data); // Trigger callback to refresh view or update state
      onClose(); // Close the modal or form after saving
    } catch (error) {
      console.error('Error saving plan:', error);
      if (error.response) {
        setError(error.response.data.message || 'Failed to save the plan.');
      } else if (error.request) {
        setError('No response from the server. Please check your network.');
      } else {
        setError('Error: ' + error.message);
      }
    }
  };

  // Handle key down for input navigation
  const handleKeyDown = (event) => {
    const focusableElements = Array.from(event.currentTarget.querySelectorAll('input, select, button'));
    const index = focusableElements.indexOf(document.activeElement);
    let nextIndex = index;

    if (event.key === 'Tab') {
      if (event.shiftKey) {
        nextIndex = index === 0 ? focusableElements.length - 1 : index - 1;
      } else {
        nextIndex = index === focusableElements.length - 1 ? 0 : index + 1;
      }
      focusableElements[nextIndex].focus();
      event.preventDefault(); // Prevent default tabbing behavior
    }
  };

  return (
    <div className="create-plan-container" onKeyDown={handleKeyDown}>
      <h2>{isEditing ? 'Edit Plan' : 'Create a New Plan'}</h2>

      {error && <p className="error-message">{error}</p>}

      <form onSubmit={handleSubmit}>
        {/* Academy Dropdown */}
        <div className="dropdown">
          <label htmlFor="academy_name">Select Sport Center:</label>
          {isEditing ? (
            <input
              type="text"
              id="academy_name"
              value={plan.academy_name}
              readOnly
              className="fixed-input"
            />
          ) : (
            <select
              id="academy_name"
              onChange={(e) => {
                const selectedAcademy = e.target.value;
                handlePlanChange('academy_name', selectedAcademy);
              }}
              value={plan.academy_name}
              required
            >
              <option value="">Select an Academy</option>
              {sportCenters.map(center => (
                <option key={center} value={center}>
                  {center}
                </option>
              ))}
            </select>
          )}
        </div>

        {/* Sport Type Field: Dropdown or Fixed Input */}
        <div className="dropdown">
          <label htmlFor="sport_name">Select Sport Type:</label>
          {isEditing ? (
            <input
              type="text"
              id="sport_name"
              value={plan.sport_name}
              readOnly
              className="fixed-input"
            />
          ) : (
            <select
              id="sport_name"
              value={plan.sport_name}
              onChange={(e) => handlePlanChange('sport_name', e.target.value)}
              required
            >
              <option value="">Select a Sport</option>
              {academySportsMap[selectedAcademy] && academySportsMap[selectedAcademy].map((sport, idx) => (
                <option key={idx} value={sport}>
                  {sport}
                </option>
              ))}
            </select>
          )}
        </div>

        {/* Sport Type Dropdown */}
       
        {/* Number of Courts */}
        {(plan.sport_name === 'Badminton' || plan.sport_name === 'TT') && (
          <div className="court-selection">
            <h3>Arena: Select Number of Courts</h3>
            <div className="court-counter">
              <button
                type="button"
                onClick={() => handlePlanChange('max_avail_arenas', Math.max(1, plan.max_avail_arenas - 1))}
                className="counter-btn"
                disabled={plan.max_avail_arenas <= 1}
              >
                <FaMinus />
              </button>
              <span className="court-count">{plan.max_avail_arenas}</span>
              <button
                type="button"
                onClick={() => handlePlanChange('max_avail_arenas', plan.max_avail_arenas + 1)}
                className="counter-btn"
              >
                <FaPlus />
              </button>
            </div>
          </div>
        )}

        {/* Unit Price Input */}
        <div className="input-field">
          <label htmlFor="price_per_arena">Unit Price:</label>
          <input
            type="text" // Keep it as text for flexibility
            id="price_per_arena"
            value={plan.price_per_arena}
            onChange={(e) => {
              const value = e.target.value;
              // Validate input: allow only numbers and one decimal point
              if (/^\d*\.?\d*$/.test(value) || value === '') {
                handlePlanChange('price_per_arena', value);
              }
            }}
            required
          />
        </div>

        {/* GST Input */}
        <div className="input-field">
          <label htmlFor="GST">GST:</label>
          <input
            type="text" // Keep it as text for flexibility
            id="GST"
            value={plan.GST}
            onChange={(e) => {
              const value = e.target.value;
              // Validate input: allow only numbers and one decimal point
              if (/^\d*\.?\d*$/.test(value) || value === '') {
                handlePlanChange('GST', value);
              }
            }}
            required
          />
        </div>

        <button type="submit" className="submit-button">{isEditing ? 'Update Plan' : 'Create Plan'}</button>
      </form>
    </div>
  );
};

export default CreatePlan;
