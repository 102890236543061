import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../style/CreateAddons.css'; 

const AddAddon = ({ onClose, onSave, addon, isEditing }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [gst, setGst] = useState('');
  const [id, setId] = useState('');
  const [error, setError] = useState('');

  // Populate fields if editing an existing addon
  useEffect(() => {
    if (isEditing && addon) {
      console.log("addon:", addon);
      setId(addon.id);
      setName(addon.name);
      setDescription(addon.description);
      setUnitPrice(addon.unitPrice);
      setGst(addon.gst);
    }
  }, [addon, isEditing]);

  const handleKeyDown = (event) => {
    const focusableElements = Array.from(event.currentTarget.querySelectorAll('input, select, textarea, button'));
    const index = focusableElements.indexOf(document.activeElement);
    let nextIndex = index;

    if (event.key === 'Tab') {
      if (event.shiftKey) {
        nextIndex = index === 0 ? focusableElements.length - 1 : index - 1;
      } else {
        nextIndex = index === focusableElements.length - 1 ? 0 : index + 1;
      }
      focusableElements[nextIndex].focus();
      event.preventDefault(); // Prevent default tabbing behavior
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Validate form fields
    if (!name || !description || !unitPrice || !gst) {
      setError('Please fill out all fields.');
      return;
    }
  
    // Create formData object
    const formData = {
      addon_name: name,
      description,
      unit_price: unitPrice,  
      GST: gst,               
      isCreatePlan: 0, 
      isCreateAddon: isEditing ? 0 : 1, 
      isCreateDiscount: 0,
      isEditPlan: 0,
      isEditAddon: isEditing ? 1 : 0,
      isEditDiscount: 0
    };
  
    // Include addon id when editing
    if (isEditing) {
      formData.id = id; // Add id to formData if editing
    }
  
    try {
      if (isEditing) {
        // Update existing addon
        console.log(formData);
        const response = await axios.post(`https://admin.machaxi.com/summer-api/admin-post-data`, formData, {
          headers: {
            'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
          }
        });
        console.log('Update response:', response);
      } else {
        // Create new addon
        const response = await axios.post('https://admin.machaxi.com/summer-api/admin-post-data', formData, {
          headers: {
            'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
          }
        });
        console.log('Create response:', response);
      }
  
      // Inform the parent component of the saved addon
      onSave(); // Fetch latest addons after saving
      onClose(); // Close the modal or form after saving
  
      // Clear the form fields
      setName('');
      setDescription('');
      setUnitPrice('');
      setGst('');
    } catch (error) {
      console.error('Error saving addon:', error);
  
      // Enhanced error handling
      if (error.response) {
        console.error('Response data:', error.response.data);
        setError(`Error: ${error.response.data.message || 'Failed to save the addon.'}`);
      } else if (error.request) {
        setError('No response from the server. Please check your network.');
      } else {
        setError(`Error: ${error.message}`);
      }
    }
  };
  
  return (
    <div className="add-page-container">
      <h2>{isEditing ? 'Edit Add-ons' : 'Add New Add-ons'}</h2>
      {error && <p className="error-message">{error}</p>}

      <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
        <div className="input-field">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="input-field">
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        <div className="input-field">
          <label htmlFor="unit-price">Unit Price:</label>
          <input
            type="text" // Keep it as text for flexible input
            id="unit-price"
            value={unitPrice}
            onChange={(e) => {
              const value = e.target.value;
              // Validate input: allow only numbers and one decimal point
              if (/^\d*\.?\d*$/.test(value)) {
                setUnitPrice(value);
              }
            }}
            required
          />
        </div>

        <div className="input-field">
          <label htmlFor="gst">GST (%):</label>
          <input
            type="text" // Keep it as text for flexible input
            id="gst"
            value={gst}
            onChange={(e) => {
              const value = e.target.value;
              // Validate input: allow only numbers and one decimal point
              if (/^\d*\.?\d*$/.test(value)) {
                setGst(value);
              }
            }}
            required
          />
        </div>

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddAddon;
