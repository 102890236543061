import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import AddDiscount from './AddDiscount'; 
import '../style/ViewDiscount.css'; 

// Required for screen readers
Modal.setAppElement('#root');

const ViewDiscount = () => {
  const [showAddDiscount, setShowAddDiscount] = useState(false);
  const [isEditing, setIsEditing] = useState(false); 
  const [currentDiscount, setCurrentDiscount] = useState(null); 
  const [discounts, setDiscounts] = useState([]);
  const [error, setError] = useState('');

  // Fetch discounts when component mounts
  useEffect(() => {
    fetchDiscounts();
  }, []);

  const fetchDiscounts = async () => {
    try {
      const response = await axios.get('https://admin.machaxi.com/summer-api/get-data', {
        headers: {
          'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
        }
      });
      console.log('Fetched Discounts:', response.data.allDiscounts);
      if (response.data && response.data.allDiscounts) {
        setDiscounts(response.data.allDiscounts);
      } else {
        setDiscounts([]);
      }
    } catch (err) {
      console.error('Error fetching discounts:', err);
      setError('Failed to fetch discounts.');
    }
  };

  const handleAddDiscount = () => {
    setCurrentDiscount(null); 
    setIsEditing(false); 
    setShowAddDiscount(true); 
  };

  const handleEditDiscount = (discount) => {
    setCurrentDiscount(discount); 
    setIsEditing(true); 
    setShowAddDiscount(true); 
  };

  const handleClose = () => {
    setShowAddDiscount(false); 
    setCurrentDiscount(null); 
    setIsEditing(false); 
  };

  const handleDeleteDiscount = async (id) => {
    const formData = {
      id: id,
      isisDisablePlan : 0, 
      isDisableAddon : 0, 
      isDisableDiscount : 1
    }
    try {
      await axios.put(`https://admin.machaxi.com/summer-api/disable-data` , formData, {
        headers: {
          'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
        }
      });
      const updatedDiscounts = discounts.filter(discount => discount.id !== id);
      setDiscounts(updatedDiscounts); 
    } catch (err) {
      console.error('Error deleting discount:', err);
      setError('Failed to delete discount.');
    }
  };

  const handleSaveDiscount = () => {
    // After adding or editing a discount, fetch the updated list
    fetchDiscounts();
    setShowAddDiscount(false); 
  };

  return (
    <div className="view-discount-container">
      <h2>Discounts</h2>
      {error && <p className="error-message">{error}</p>}
      <div className="button-container">
        <button className="add-discount-button" onClick={handleAddDiscount}>
          Add Discount
        </button>
      </div>

      <table className="discounts-table">
        <thead>
          <tr>
            <th>Start Range</th>
            <th>End Range</th>
            <th>Discount Percentage</th>
            <th>Upto Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {discounts.map((discount) => (
            <tr key={discount.id}>
              <td>{discount.start_range}</td>
              <td>{discount.end_range}</td>
              <td>{discount.discount_percentage}%</td> 
              <td>{discount.upto_amount}</td>
              <td>
                <button 
                  className="edit-button" 
                  onClick={() => handleEditDiscount(discount)}
                >
                  Edit
                </button>
                <button 
                  className="delete-button" 
                  onClick={() => handleDeleteDiscount(discount.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for adding/editing discounts */}
      <Modal
        isOpen={showAddDiscount}
        onRequestClose={handleClose}
        contentLabel="Add/Edit Discount Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h3>{isEditing ? 'Edit Discount' : 'Add New Discount'}</h3>
        {/* Pass necessary props to AddDiscount */}
        <AddDiscount 
          onClose={handleClose} 
          onSave={handleSaveDiscount} 
          discount={currentDiscount} 
          isEditing={isEditing} 
        />
        <button onClick={handleClose} className="close-modal-button">
          Close
        </button>
      </Modal>
    </div>
  );
};

export default ViewDiscount;
