// src/components/DashboardWrapper.js
import React, { useEffect } from 'react';
import Dashboard from './Dashboard'; // Import Dashboard component
import { useNavigate } from 'react-router-dom';

const DashboardWrapper = ({ handleLogout }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const storedLocation = sessionStorage.getItem('currentLocation');
    if (storedLocation) {
      navigate(storedLocation);
    }

    const currentLocation = window.location.pathname;
    sessionStorage.setItem('currentLocation', currentLocation);

    return () => {
      sessionStorage.removeItem('currentLocation'); // Cleanup on unmount
    };
  }, [navigate]);

  return <Dashboard handleLogout={handleLogout} />;
};

export default DashboardWrapper;
