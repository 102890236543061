import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import '../style/Dashboard.css';
import '../components/ViewPlan';
import ViewPlan from '../components/ViewPlan';
import image from '../components/Machaxi-03 (1).png';

const Dashboard = ({ handleLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const menuRef = useRef(null); // Create a ref for the menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener to detect clicks outside the menu
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleHomeClick = () => {
    navigate("/dashboard");
    setIsMenuOpen(false);
  };

  return (
    <div className="dashboard-container">
      {/* Navbar */}
      <motion.div
        className="navbar"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
      >
        <div className="left-section">
          <motion.div
            className="hamburger-menu"
            onClick={toggleMenu}
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </motion.div>

          {/* Logo Link */}
          <Link to="/dashboard" onClick={() => setIsMenuOpen(false)}>
          <div>
  <img src={image} alt="Company Logo" className="navbar-logo" />
</div>

          </Link>
        </div>

        <h1>Welcome to Machaxi</h1>

        {/* Logout button */}
        <motion.button
          className="logout-button"
          onClick={handleLogout}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          Logout
        </motion.button>
      </motion.div>

      {/* Hamburger Menu Content */}
      {isMenuOpen && (
        <motion.div
          className="menu-content"
          initial={{ x: -200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          ref={menuRef} // Attach ref to the menu
        >
          <ul>
            
            <li>
              <Link to="/dashboard/view-plan" className="menu-link" onClick={() => setIsMenuOpen(false)}>Plans</Link>
            </li>
            <li>
              <Link to="/dashboard/view-addon" className="menu-link" onClick={() => setIsMenuOpen(false)}>Addons</Link>
            </li>
            <li>
              <Link to="/dashboard/view-discount" className="menu-link" onClick={() => setIsMenuOpen(false)}>Discount</Link>
            </li>
            <li>
              <Link to="/dashboard/view-quotations" className="menu-link" onClick={() => setIsMenuOpen(false)}>Quotation</Link>
            </li>
          </ul>
        </motion.div>
      )}

      {/* Main Content */}
      <div className="main-content">
        {/* Show banner only on dashboard route */}
        {location.pathname === "/dashboard" && (
         <ViewPlan/>
        )}
        <Outlet /> {/* Renders Create Plan, View Plan, etc. */}
      </div>
    </div>
  );
};

export default Dashboard;