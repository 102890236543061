import React, { useState, useEffect } from 'react'; // Import useState and useEffect
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import DashboardWrapper from './components/DashboardWrapper'; // Import DashboardWrapper
import ViewPlan from './components/ViewPlan';
import LoginPage from './login/Login';
import ViewQuotation from './components/ViewQuotation';
import ViewAddons from './components/ViewAddons';
import ViewDiscount from './components/ViewDiscount';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={isAuthenticated ? <DashboardWrapper handleLogout={handleLogout} /> : <Navigate to="/login" />} />
        <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" /> : <LoginPage handleLogin={handleLogin} />} />
        <Route path="/dashboard/*" element={isAuthenticated ? <DashboardWrapper handleLogout={handleLogout} /> : <Navigate to="/login" />}>
          <Route path="view-plan" element={<ViewPlan />} />
          <Route path="view-addon" element={<ViewAddons />} />
          <Route path="view-discount" element={<ViewDiscount />} />
           <Route path="view-quotations" element={<ViewQuotation />} /> 
        </Route>
        <Route path="*" element={<Navigate to="/" />} /> Catch-all route
      </Routes>
    </Router>
  );
};

export default App;
