import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../style/ViewQuotations.css'; // Ensure you have your CSS file correctly linked

const ViewQuotations = () => {
  const [quotations, setQuotations] = useState([]);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState(''); // State for search term

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get('https://admin.machaxi.com/summer-api/get-data', {
        headers: {
          'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
        }
      });
      const fetchedQuotations = response.data.allQuotations.map((quotation) => ({
        id: quotation.id,
        person: quotation.person,
        organisation: quotation.organisation,
        contactNumber: quotation.contact_number,
        addons: quotation.addons.map(addon => addon).join(', '),
        quotationNumber: quotation.quotationId, // Assuming this property exists in your API response
        centerSelected: quotation.centre // Assuming this property exists in your API response
      }));
      setQuotations(fetchedQuotations);
    } catch (error) {
      console.error('Error fetching quotations:', error);
      setError('Failed to fetch quotations. Please try again.');
    }
  };

  // Function to download the PDF
  const downloadPDF = async (id) => {
    try {
      const response = await axios.get(`https://admin.machaxi.com/summer-api/download-pdf/${id}`, {
        responseType: 'blob',
        headers: {
          'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
        }
      });

      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `quotation_${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
      setError('Failed to download PDF. Please try again.');
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Function to filter quotations based on the search term
  const filteredQuotations = quotations.filter((quotation) => {
    return (
      quotation.quotationNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      quotation.person.toLowerCase().includes(searchTerm.toLowerCase()) ||
      quotation.organisation.toLowerCase().includes(searchTerm.toLowerCase()) ||
      quotation.contactNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      quotation.addons.toLowerCase().includes(searchTerm.toLowerCase()) ||
      quotation.centerSelected.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <div className="view-quotations-container">
      <h2>Quotations</h2>
      {error && <p className="error-message">{error}</p>}

      {/* Search Input */}
      <input
        type="text"
        placeholder="Search Quotations"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />

      <table className="quotations-table">
        <thead>
          <tr>
            <th>Quotation Number</th> {/* Quotation number column */}
            <th>Person</th>
            <th>Organisation</th>
            <th>Contact Number</th>
            <th>Add-ons</th>
            <th>Center</th> {/* New column for Center Selected */}
            <th>Download PDF</th>
          </tr>
        </thead>
        <tbody>
          {filteredQuotations.slice().reverse().map((quotation) => (
            <tr key={quotation.id} className="quotation-row">
              <td className="centered">{quotation.quotationNumber}</td> {/* Centered quotation number */}
              <td className="centered">{quotation.person}</td>
              <td className="centered">{quotation.organisation}</td>
              <td className="centered">{quotation.contactNumber}</td>
              <td className="centered">{quotation.addons}</td>
              <td className="centered">{quotation.centerSelected}</td> {/* Centered center selected */}
              <td className="centered">
                <button onClick={() => downloadPDF(quotation.id)}>Download PDF</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewQuotations;
