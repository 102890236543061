import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios
import '../style/LoginPage.css';
import { motion } from "framer-motion";

const LoginPage = ({ handleLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // New loading state
  const [passwordVisible, setPasswordVisible] = useState(false); // New state for password visibility
  const navigate = useNavigate(); // useNavigate hook

  const handleLoginClick = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    setError(""); // Reset the error message
    setLoading(true); // Set loading to true

    // Check if username and password are provided
    if (!username || !password) {
      setError("All fields are required");
      setLoading(false); // Reset loading
      return;
    }

    try {
      // Update the endpoint to use '/auth/login'
      const response = await axios.post("https://admin.machaxi.com/summer-api/login", { 
        username, 
        password 
      });

      // If login is successful
      handleLogin(); // Call the handleLogin function from props
      navigate("/dashboard"); // Redirect to dashboard on successful login

    } catch (error) {
      // Handle error response
      if (error.response) {
        setError(error.response.data.error || error.response.data); // Extract error message
      } else {
        // console.error("Login error:", error);
        setError("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <motion.div
      className="login-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.2 }}
    >
      <motion.div
        className="login-form"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.8, ease: "easeInOut" }}
      >
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          Welcome
        </motion.h2>

        <form onSubmit={handleLoginClick}>
          <motion.input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            whileFocus={{ scale: 1.05, borderColor: "#00bfff" }}
            transition={{ duration: 0.3 }}
            className="input"
          />

          <div className="password-input-container"> {/* Wrapper for password input */}
            <motion.input
              type={passwordVisible ? "text" : "password"} // Toggle password visibility
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              whileFocus={{ scale: 1.05, borderColor: "#00bfff" }}
              transition={{ duration: 0.3 }}
              className="input password-input"
            />
            <button
              type="button"
              className="toggle-password-visibility"
              onClick={() => setPasswordVisible(!passwordVisible)} // Toggle visibility state
            >
              {passwordVisible ? (
                <motion.svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="20" 
                  height="20" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.2 }}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12s2.5-4.5 9-4.5 9 4.5 9 4.5-2.5 4.5-9 4.5S3 12 3 12z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </motion.svg>
              ) : (
                <motion.svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="20" 
                  height="20" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.2 }}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.803 17.803a10.96 10.96 0 01-1.586.848m-2.217-.963A10.967 10.967 0 0112 18.92c-5.09 0-9.672-3.596-10.499-4.348a1.009 1.009 0 010-1.224C2.328 10.675 6.91 7.08 12 7.08c.541 0 1.07.042 1.578.123m-1.678 9.438A10.967 10.967 0 0112 18.92c5.09 0 9.672-3.596 10.499-4.348a1.009 1.009 0 000-1.224C21.672 10.675 17.09 7.08 12 7.08c-.541 0-1.07.042-1.578.123" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3l18 18" />
                </motion.svg>
              )}
            </button>
          </div>

          <motion.button
            className="button"
            type="submit" // Set button type to submit
            whileHover={{ scale: 1.1, backgroundColor: "#00a3cc" }}
            whileTap={{ scale: 0.95 }}
            disabled={loading} // Disable button while loading
          >
            {loading ? "Logging in..." : "Log In"} {/* Display loading text */}
          </motion.button>
        </form>

        {error && (
          <motion.p
            className="error-message"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {error}
          </motion.p>
        )}
      </motion.div>
    </motion.div>
  );
};

export default LoginPage;
