import React, { useState } from 'react';
import axios from 'axios'; // Import axios for API calls
import '../style/AddDiscount.css'; // Ensure you have a CSS file for styles

const AddDiscount = ({ onClose, onSave, discount = null, isEditing = false }) => {
  // Initialize state with either the discount to edit or an empty discount
  const [discounts, setDiscounts] = useState(
    discount ? [discount] : [{ start_range: '', end_range: '', discount_percentage: '', upto_amount: '' }]
  );
  const [error, setError] = useState('');

  // Handle input change for each discount field
  const handleChange = (index, field, value) => {
    const updatedDiscounts = [...discounts];
    updatedDiscounts[index][field] = value;
    setDiscounts(updatedDiscounts);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate all discount entries
    for (const discount of discounts) {
      if (!discount.start_range || !discount.end_range || !discount.discount_percentage || !discount.upto_amount) {
        setError('Please fill out all fields.');
        return;
      }
    }

    try {
      const newDiscounts = [];

      for (const discountItem of discounts) {
        let formData = {
          start_range: discountItem.start_range,
          end_range: discountItem.end_range,
          discount_percentage: discountItem.discount_percentage,
          upto_amount: discountItem.upto_amount,
          isCreatePlan: 0,
          isCreateAddon: 0,
          isCreateDiscount: isEditing ? 0 : 1,
          isEditPlan: 0,
          isEditAddon: 0,
          isEditDiscount: isEditing ? 1 : 0,
        };

        if (isEditing && discountItem.id) {
          // Update existing discount
          formData.id = discountItem.id;
        }

        console.log("The discount data is : ", formData);
        const response = await axios.post('https://admin.machaxi.com/summer-api/admin-post-data', formData, {
          headers: {
            'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
          }
        });

        newDiscounts.push(response.data);
      }

      // Clear form and error
      setDiscounts([{ start_range: '', end_range: '', discount_percentage: '', upto_amount: '' }]);
      setError('');

      // Notify parent component to fetch updated discounts
      onSave(); // Trigger fetch in ViewDiscount
      onClose();
    } catch (error) {
      console.error("Error submitting discounts:", error);
      setError('Failed to submit discounts. Please try again.');
    }
  };

  const handleKeyDown = (event) => {
    const focusableElements = Array.from(event.currentTarget.querySelectorAll('input, select, textarea, button'));
    const index = focusableElements.indexOf(document.activeElement);
    let nextIndex = index;

    if (event.key === 'Tab') {
      if (event.shiftKey) {
        nextIndex = index === 0 ? focusableElements.length - 1 : index - 1;
      } else {
        nextIndex = index === focusableElements.length - 1 ? 0 : index + 1;
      }
      focusableElements[nextIndex].focus();
      event.preventDefault(); // Prevent default tabbing behavior
    }
  };

  return (
    <div className="add-discount-container" onKeyDown={handleKeyDown}>
      <h2>{isEditing ? 'Edit Discount' : 'Add Discounts'}</h2>
      {error && <p className="error-message">{error}</p>}

      <form onSubmit={handleSubmit}>
        <div className="discount-fields-container">
          {discounts.map((discountItem, index) => (
            <div key={index} className="discount-field">
              <div className="input-field">
                <label htmlFor={`start_range-${index}`}>Start Range:</label>
                <input
                  type="text" // Change type to text for flexible input
                  id={`start_range-${index}`}
                  value={discountItem.start_range}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Validate input: allow only numbers and one decimal point
                    if (/^\d*\.?\d*$/.test(value)) {
                      handleChange(index, 'start_range', value);
                    }
                  }}
                  required
                />
              </div>

              <div className="input-field">
                <label htmlFor={`end_range-${index}`}>End Range:</label>
                <input
                  type="text" // Change type to text for flexible input
                  id={`end_range-${index}`}
                  value={discountItem.end_range}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Validate input: allow only numbers and one decimal point
                    if (/^\d*\.?\d*$/.test(value)) {
                      handleChange(index, 'end_range', value);
                    }
                  }}
                  required
                />
              </div>

              <div className="input-field">
                <label htmlFor={`discount_percentage-${index}`}>Discount Percentage:</label>
                <input
                  type="text" // Change type to text for flexible input
                  id={`discount_percentage-${index}`}
                  value={discountItem.discount_percentage}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Validate input: allow only numbers and one decimal point
                    if (/^\d*\.?\d*$/.test(value)) {
                      handleChange(index, 'discount_percentage', value);
                    }
                  }}
                  required
                />
              </div>

              <div className="input-field">
                <label htmlFor={`upto_amount-${index}`}>Upto Amount:</label>
                <input
                  type="text" // Change type to text for flexible input
                  id={`upto_amount-${index}`}
                  value={discountItem.upto_amount}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Validate input: allow only numbers and one decimal point
                    if (/^\d*\.?\d*$/.test(value)) {
                      handleChange(index, 'upto_amount', value);
                    }
                  }}
                  required
                />
              </div>

              {index < discounts.length - 1 && <hr className="separator-line" />}
            </div>
          ))}
        </div>

        <div className="submit-container">
          <button type="submit" className="submit-button">{isEditing ? 'Update' : 'Submit'}</button>
        </div>
      </form>
    </div>
  );
};

export default AddDiscount;
